import React from "react"
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const {t}= useTranslation()
  return (
  <Layout>
    <SEO title={t("aboutTitle")} />
      <div style={{"marginTop": "10em", "marginBottom": "10em", "textAlign": "center"}}>
        <h2>{t("aboutTitle")}</h2>
        <h1>{t("aboutParagraph")}</h1>
      </div>
  </Layout>)
}

export default IndexPage

